import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import arrow from "../img/arrow.svg";

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, items } =
    React.useContext(VisibilityContext);
  const isMoreThanOne = items.size > 1;

  return (
    !isFirstItemVisible &&
    isMoreThanOne && (
      <div
        className="v-recipes-page__scroll v-recipes-page__scroll--left"
        onClick={() => scrollPrev()}
      >
        <img src={arrow} alt="Arrow" />
      </div>
    )
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext, items } =
    React.useContext(VisibilityContext);
  const isMoreThanOne = items.size > 1;

  return (
    !isLastItemVisible &&
    isMoreThanOne && (
      <div
        className="v-recipes-page__scroll v-recipes-page__scroll--right"
        onClick={() => scrollNext()}
      >
        <img src={arrow} alt="Arrow" />
      </div>
    )
  );
}

class RecipeRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const category =
      typeof window !== "undefined" &&
      window.location.hash &&
      window.location.hash.slice(1);

    const getLinkClassNames = (_category) =>
      category == _category ? "v-recipes-page__filter--active" : "";

    const recipes = category
      ? posts &&
        posts.length > 0 &&
        posts.filter((post) =>
          post.node.frontmatter.category?.includes(category)
        )
      : posts;

    const shouldShowList = (recipes || []).length > 0;

    return (
      <div>
        <div className="v-recipes-page__filter">
          <Link className={getLinkClassNames("")} to="/przepisy">
            wszystkie
          </Link>
          <Link
            className={getLinkClassNames("wytrawne")}
            to="/przepisy#wytrawne"
          >
            wytrawne
          </Link>
          <Link
            className={getLinkClassNames("naslodko")}
            to="/przepisy#naslodko"
          >
            na słodko
          </Link>
          <Link className={getLinkClassNames("tempeh")} to="/przepisy#tempeh">
            tempeh
          </Link>
        </div>
        {shouldShowList && (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            transitionBehavior="smooth"
          >
            {recipes &&
              recipes.map(({ node: post }) => (
                <Link
                  className="v-recipes-page__item"
                  to={post.fields.slug}
                  itemId={post.fields.slug}
                  key={post.fields.slug}
                >
                  <PreviewCompatibleImage imageInfo={post.frontmatter.image} />
                  <span className="v-recipes-page__item-title">
                    {post.frontmatter.title}
                  </span>
                </Link>
              ))}
          </ScrollMenu>
        )}
      </div>
    );
  }
}

RecipeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function RecipeRoll() {
  return (
    <StaticQuery
      query={graphql`
        query RecipeRollQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "recipe-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  category
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        width: 360
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <RecipeRollTemplate data={data} count={count} />}
    />
  );
}
