import * as React from "react";

import Layout from "../../components/Layout";
import RecipeRoll from "../../components/RecipeRoll";
import Navbar from "../../components/Navbar";

import "../styles/recipes.sass";

export default class RecipeIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar dark externalPage />
        <section className="v-section v-section--blue v-section--full-screen v-recipes-page">
          <div className="v-fullwidth-content">
            <div className="container">
              <div className="v-recipes-page__header">
                <div className="v-recipes-page__header-content-title">
                  NASZE PRZEPISY Z TOFU
                </div>
                <div className="v-recipes-page__header-content-desc">
                  te przepisy są sprawdzone!
                </div>
              </div>
            </div>
            <RecipeRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
